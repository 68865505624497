<template>
  <v-data-table
    :search="searchInput"
    :headers="strandHeaders"
    :items="strands"
    :loading="strandsLoading"
    :footer-props="{
      itemsPerPageOptions: strandsPerPage,
    }"
  >
    <template v-slot:[`item.icon`]="{ item }">
      <v-chip :class="`secondary-${item.color}-bg`" label text-color="white">
        <v-icon :color="item.color">mdi-{{ item.icon }}</v-icon>
      </v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle rounded>
        <v-btn
          class="remove-toggled-state"
          small
          color="success"
          @click="openDialog('view', item.id)"
        >
          <v-icon small color="white"> mdi-eye </v-icon>
        </v-btn>
        <v-btn
          class="remove-toggled-state"
          small
          color="primary"
          @click="openDialog('edit', item.id)"
        >
          <v-icon small color="white"> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
          class="remove-toggled-state"
          small
          color="error"
          @click="openDialog('delete', item.id)"
        >
          <v-icon small color="white">mdi-delete</v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "StrandList",
  props: {
    searchInput: {
      type: String,
      default: "",
    },
    filterStrandsByGrade: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      strandsPerPage: [10, 15, 25, 50, 100],
    };
  },
  computed: {
    strands: function () {
      return this.$store.state.strandsModule.strands;
    },
    strandsLoading: function () {
      return this.$store.state.strandsModule.strandsLoading;
    },
    strandHeaders: function () {
      return [
        {
          text: "Strand Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Semester",
          align: "start",
          sortable: true,
          value: "semester",
        },
        {
          text: "Grade Level",
          align: "center",
          sortable: true,
          value: "gradeLevel",
          filter: (value) => {
            if (!this.filterStrandsByGrade) {
              return true;
            }
            return this.filterStrandsByGrade == value;
          },
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ];
    },
  },
  methods: {
    openDialog(action, strandId) {
      this.$emit("openDialog", { action: action, strandId: strandId });
    },
  },
  async mounted() {
    await this.$store.dispatch("strandsModule/getStrands");
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
