<template>
  <v-card class="elevation-2">
    <v-dialog v-model="strandViewDialog" max-width="600px">
      <StrandInfo @closeDialog="closeDialog($event)" />
    </v-dialog>
    <v-dialog v-model="strandAddDialog" max-width="600px">
      <StrandForm
        strandFormTitle="Add Strand"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog v-model="strandEditDialog" max-width="600px">
      <StrandForm
        strandFormTitle="Update Strand"
        :isUpdating="true"
        :strandData="strandData"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog v-model="strandDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="main-red-bg white--text">
          <span class="text-h5">Delete Strand</span>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <span class="text-h6">Are you sure you want to delete strand ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="ma-2 white--text px-3"
            @click="closeDialog('delete')"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="deleteStrand"
            :loading="deletingStrand"
            color="warning"
            class="ma-2 white--text px-3"
          >
            <v-icon left dark> mdi-delete </v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-row>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex align-center"
          cols="12"
          sm="6"
        >
          <h2 class="mx-2">Strands Table</h2>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex justify-end"
          cols="12"
          sm="6"
        >
          <v-btn-toggle>
            <v-btn
              color="success"
              class="px-4 remove-toggled-state"
              light
              medium
              @click="openDialog({ action: 'add', strandId: null })"
            >
              Add Strand
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <FilterSelect
            filterLabel="Grade"
            :filterOptions="strandGradeLevels"
            @change="filterStrandsByGrade = $event"
          />
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <SearchQuery @search="searchInputQuery = $event" />
        </v-col>
      </v-row>
    </v-card-title>
    <StrandList
      :searchInput="searchInputQuery"
      :filterStrandsByGrade="filterStrandsByGrade"
      @openDialog="openDialog($event)"
    />
  </v-card>
</template>

<script>
import SearchQuery from "@/components/SearchQuery";
import FilterSelect from "@/components/FilterSelect";
import StrandList from "@/components/StrandList";
import StrandForm from "@/components/StrandForm";
import StrandInfo from "@/components/StrandInfo";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Strands",
  },
  name: "StrandIndex",
  components: {
    SearchQuery,
    FilterSelect,
    StrandList,
    StrandForm,
    StrandInfo,
  },
  data() {
    return {
      filterStrandsByGrade: "",
      searchInputQuery: "",
      strandGradeLevels: ["7", "8", "9", "10", "11", "12"],
      strandViewDialog: false,
      strandAddDialog: false,
      strandEditDialog: false,
      strandDeleteDialog: false,
      deletingStrand: false,
    };
  },
  computed: {
    ...mapGetters({
      getStrand: "strandsModule/getStrand",
    }),
    strandData() {
      return this.getStrand;
    },
  },
  methods: {
    async openDialog(dialogObject) {
      if (dialogObject.strandId) {
        await this.$store.dispatch(
          "strandsModule/getStrandById",
          dialogObject.strandId
        );
      }
      switch (dialogObject.action) {
        case "view":
          this.strandViewDialog = true;
          break;
        case "add":
          this.strandAddDialog = true;
          break;
        case "edit":
          this.strandEditDialog = true;
          break;
        case "delete":
          this.strandDeleteDialog = true;
          break;
      }
    },
    closeDialog(dialog) {
      switch (dialog) {
        case "view":
          this.strandViewDialog = false;
          break;
        case "add":
          this.strandAddDialog = false;
          break;
        case "edit":
          this.strandEditDialog = false;
          break;
        case "delete":
          this.strandDeleteDialog = false;
          break;
      }
    },
    async deleteStrand() {
      const strandId = this.getStrand.id;
      this.deletingStrand = true;
      await this.$store.dispatch("strandsModule/deleteStrandById", strandId);
      this.deletingStrand = false;
      this.closeDialog("delete");
    },
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
