<template>
  <v-card>
    <v-card-title class="main-red-bg white--text">
      <span class="text-h5">{{ strandFormTitle }}</span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-form v-model="isStrandFormValid" ref="strandForm">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="strandFormData.name"
                label="Name"
                :rules="[validators.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" :sm="gradeLevelColSet">
              <v-text-field
                v-model="strandFormData.gradeLevel"
                @input="updateGradeLevel"
                type="number"
                label="Grade Level"
                :rules="[validators.required, validGradeLevel]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="!juniorHigh">
              <v-text-field
                v-model="strandFormData.semester"
                type="number"
                label="Semester"
                :rules="[validators.required, validators.validSemester]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="strandFormData.sectionIds"
                :loading="updatingAutocomplete"
                @change="validateArray('sectionIds', $event)"
                label="Sections"
                class="chip-color"
                multiple
                deletable-chips
                small-chips
                :items="filteredSectionsAutocomplete"
                :rules="[validators.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="strandFormData.subjectIds"
                :loading="updatingAutocomplete"
                @change="validateArray('subjectIds', $event)"
                label="Subjects"
                class="chip-color"
                multiple
                deletable-chips
                small-chips
                :items="filteredSubjectsAutocomplete"
                :rules="[validators.required]"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" class="ma-2 white--text px-3" @click="closeDialog">
        Cancel
      </v-btn>
      <v-btn
        :disabled="!isStrandFormValid"
        :loading="loadingSubmit"
        @click="submitStrandForm"
        color="success"
        class="ma-2 white--text px-3"
      >
        <v-icon left dark> mdi-send </v-icon>
        {{ formActionLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from "@/helpers/validationHelper";
import { mapGetters } from "vuex";
export default {
  name: "StrandForm",
  props: {
    strandFormTitle: {
      type: String,
      default: "Add Strand",
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    strandData: {
      type: Object,
      default: () => ({
        name: "",
        gradeLevel: "",
        semester: "",
        subjectIds: "",
        sectionIds: "",
      }),
    },
  },
  data() {
    return {
      isStrandFormValid: false,
      loadingSubmit: false,
      juniorHigh: false,
      updatingAutocomplete: false,
      gradeLevelColSet: 3,
      validators: validators,
      filteredSectionsAutocomplete: [],
      filteredSubjectsAutocomplete: [],
      strandFormData: {
        name: "",
        gradeLevel: "",
        semester: "",
        subjectIds: "",
        sectionIds: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      sectionsAutocomplete: "sectionsModule/getSectionsAutocomplete",
      subjectsAutocomplete: "_subjectsModule/getSubjectsAutocomplete",
    }),
    formActionLabel: function () {
      return this.isUpdating ? "Update" : "Submit";
    },
  },
  methods: {
    validGradeLevel(gradeLevel) {
      return gradeLevel > 0 && gradeLevel < 13 ? true : "Invalid Grade Level";
    },
    validateArray(arrayName, arrayValue) {
      if (arrayValue.length === 0) {
        this.strandFormData[arrayName] = "";
      }
    },
    updateGradeLevel(gradeLevel) {
      if (gradeLevel > 10) {
        this.juniorHigh = false;
        this.gradeLevelColSet = 3;
      } else {
        this.gradeLevelColSet = 6;
        this.juniorHigh = true;
        this.strandFormData.semester = null;
      }
      this.updatingAutocomplete = true;

      this.filteredSectionsAutocomplete = this.sectionsAutocomplete.filter(
        (section) => section.gradeLevel == gradeLevel
      );
      this.filteredSubjectsAutocomplete = this.subjectsAutocomplete.filter(
        (subject) => subject.gradeLevel == gradeLevel
      );

      setTimeout(() => {
        this.updatingAutocomplete = false;
      }, 1000);
    },
    async submitStrandForm() {
      this.loadingSubmit = true;
      const revalidatedForm = this.$refs.strandForm.validate();
      if (revalidatedForm) {
        await this.$store.dispatch("strandsModule/submitStrandForm", {
          strand: this.strandFormData,
          isUpdating: this.isUpdating,
        });

        this.loadingSubmit = false;
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$refs.strandForm.reset();
      this.strandFormData = {
        name: "",
        gradeLevel: "",
        semester: "",
        subjectIds: "",
        sectionIds: "",
      };
      this.$store.commit("strandsModule/clearStrand");
      this.$emit("closeDialog", this.isUpdating ? "edit" : "add");
    },
  },
  watch: {
    strandData: {
      handler(newStrandFormData) {
        if (this.isUpdating) {
          this.filteredSectionsAutocomplete = this.sectionsAutocomplete.filter(
            (section) => section.gradeLevel == newStrandFormData.gradeLevel
          );
          this.filteredSubjectsAutocomplete = this.subjectsAutocomplete.filter(
            (subject) => subject.gradeLevel == newStrandFormData.gradeLevel
          );
          this.strandFormData = newStrandFormData;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("_subjectsModule/getSubjects");
    await this.$store.dispatch("sectionsModule/getSections");
  },
};
</script>
<style>
.chip-color .v-chip--select {
  background-color: #f96961 !important;
  color: white !important;
}
.chip-color .v-chip__close {
  color: white !important;
}
</style>
