<template>
  <v-card>
    <v-card-title class="main-red-bg white--text">
      <span class="text-h5">Strand Details</span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Strand Name</v-list-item-subtitle>
            <v-list-item-title class="title">
              {{ strandData.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Grade Level</v-list-item-subtitle>
            <v-list-item-title class="title">
              {{ strandData.gradeLevel }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Semester</v-list-item-subtitle>
            <v-list-item-title class="title">
              {{ strandData.semester }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card tile>
        <v-list flat>
          <v-subheader class="title">Subjects</v-subheader>
          <v-list-item-group>
            <v-list-item v-for="(subject, key) in displaySubjects" :key="key">
              <v-list-item-icon>
                {{ subject.gradeLevel }}
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="subject.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
      <v-divider class="mb-2"></v-divider>
      <v-card tile>
        <v-list flat>
          <v-subheader class="title">Sections</v-subheader>
          <v-list-item-group>
            <v-list-item v-for="(section, key) in displaySections" :key="key">
              <v-list-item-icon>
                {{ section.gradeLevel }}
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="section.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" class="ma-2 white--text px-3" @click="closeDialog">
        Back
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "StrandInfo",
  data() {
    return {
      displaySections: [],
      displaySubjects: [],
    };
  },
  computed: {
    ...mapGetters({
      sections: "sectionsModule/getSections",
      subjects: "_subjectsModule/getSubjects",
      strandData: "strandsModule/getStrand",
    }),
  },
  watch: {
    strandData: {
      handler(newStrand) {
        if (newStrand.subjectIds) {
          let displaySubjects = [];
          newStrand.subjectIds.map((subjectId) => {
            const subject = this.subjects.find(
              (subject) => subject.id === subjectId
            );
            displaySubjects.push(subject);
          });

          this.displaySubjects = displaySubjects;
        }

        if (newStrand.sectionIds) {
          let displaySections = [];
          newStrand.sectionIds.map((sectionId) => {
            const section = this.sections.find(
              (section) => section.id === sectionId
            );
            displaySections.push(section);
          });

          this.displaySections = displaySections;
        }
      },
      immediate: true,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", "view");
    },
  },
  async mounted() {
    await this.$store.dispatch("_subjectsModule/getSubjects");
    await this.$store.dispatch("sectionsModule/getSections");
  },
};
</script>
<style scoped>
.word-break-unset {
  word-break: unset;
}
</style>
